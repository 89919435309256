.datepicker1,.select_sub {
  width: 100%;
  border: 1px solid #00a6b1;
  padding: 5px;
  border-radius: 5px;
  color: #000 !important;
  background-color: transparent;
}

.datepicker1:focus {
  border: 2px solid #00a6b1;
}

.react-select__container {
  width: 200px;
}

.react-select__control {
  /* background-color: black !important; */
  border-color: black;
  color: black !important;
}

.react-select__single-value {
  color: black;
  color: white !important;
}

.react-select__option {
  color: black !important;
  /* background-color: black !important; */
}

.react-select__indicator-separator {
  background-color: black;
}

.react-select__dropdown-indicator {
  color: black;
  color: black !important;
}

/* .react-select-3-listbox {

} */
