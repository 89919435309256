.datepicker {
  width: 100%;
  border: 1px solid #00a6b1;
  padding: 5px;
  border-radius: 5px;
  color: #006887;
  background-color: transparent;
}

.datepicker:focus{
  border: 2px solid #00a6b1;

}

