.datepicker1, .select_sub {
  width: 100%;
  border: 1px solid #00a6b1;
  padding: 5px;
  border-radius: 5px;
  color: #000 !important;
}

.datepicker1:focus{
  border: 2px solid #00a6b1;
  color: #000;

}

