.sidebar_nav {
    background: linear-gradient(to bottom right, transparent, rgba(217, 217, 217, 0.5), transparent) !important;
}

.sidebar_links {
    background: radial-gradient(1.18% 0.08% at 49.75% 0%, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(217, 217, 217, 0.35);
    width: 75%;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    
}
.sidebar_link {
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.25rem 0.5rem;
}
.sidebar_link_item {
    /* width: 90%; */
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
}
.sidebar_link_item > p {
    padding-left: 1rem;
}

.sidebar_icons {
    padding-left: 2rem;
    display: flex;
    justify-content: space-around;
    /* width: 100%; */
}

.sidebar_links:hover {
    background: radial-gradient(59.55% 78.71% at 23.75% 13.75%, #0177D9 0%, #01298B 100%);
    color: white;
}



.table__head {
    background: radial-gradient(59.55% 78.71% at 23.75% 13.75%, #0177D9 0%, #01298B 100%) !important;
    color: white;
}


/*  */
.active-selector {
    background: radial-gradient(59.55% 78.71% at 23.75% 13.75%, #0177D9 0%, #01298B 100%);
    box-shadow: rgba(255, 255, 255, 0.85) 1px 0px 2px 0px inset, rgba(151, 199, 255, 0.2) -2px 0px 2px 0px, rgb(1, 119, 217) -4px 0px 24px 0px;
    border-radius: 99px 0 0 99px;
    height: 100%;
    width: 0.5rem;
    position: absolute;
    right: -23px;
    top: 0;
}


.nav__bar__link {
    border: none !important;
    background: rgba(217, 217, 217, 0.7) !important;
    padding: 1rem;
    margin: 10px 5px !important;
    border-radius: 0.65rem;
    
    /* box-shadow: 0 2px 25px 2px rgb(0 0 0 / .25); */
}

.sidebar_link_active {
    background: radial-gradient(59.55% 78.71% at 23.75% 13.75%, #0177D9 0%, #01298B 100%);
    color: white !important;
    border: none !important;
    
    padding: 1rem;
    margin: 10px 5px !important;
    border-radius: 0.65rem;
    
    /* box-shadow: 0 2px 25px 2px rgb(0 0 0 / .25); */
}

.app_btn {
    background: radial-gradient(59.55% 78.71% at 23.75% 13.75%, #0177D9 0%, #01298B 100%) !important;
    color: white;
}


.side__bar_link_active {
    background: radial-gradient(59.55% 78.71% at 23.75% 13.75%, #0177D9 0%, #01298B 100%);
    color: white !important;
    border: none !important;
    
    /* padding: 0 1rem; */
    /* margin: 10px 5px !important; */
    width: 75%;
    border-radius: 0.65rem;
    
    /* box-shadow: 0 2px 25px 2px rgb(0 0 0 / .25); */
}